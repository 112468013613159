import React, { Component } from "react";
import {
    Label,
    Input,
    Form,
    FormGroup,
    Row,
    Col,
    Button,
    Table,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter
} from "reactstrap";
import api from "../../Services/api.js";
import _api from "../../Services/_api.js";
import axios from "axios";
import util from "../../util";
import Header from "../../components/header";
import InputMask from "react-input-mask";
import { Redirect, Link } from "react-router-dom";
import {
    NotificationContainer,
    NotificationManager
} from "react-notifications";
import ContractsPicker from "../../components/contract_selector";
import Breadcrumbs from "../../components/breadcrumbs";
import moment from "moment";
import "moment/locale/pt-br";
import { button as buttonTheme } from "../../themes";

import "./styles.css";

import ReactGA from "react-ga";

export default class CheckList extends Component {
    constructor() {
        super();
        this.state = {
            redirect: "",

            // contractId: util.queryParams().contract,
            // contractId: util.queryParams().contract ?
            //     util.queryParams().contract
            //     : "0",
            contractId: util.queryParams().contract ?
                util.queryParams().contract
                : JSON.parse(localStorage.getItem('userContract')).propostaContratoGuid,
            moves: [],
            email: "",
            lastMove: "",

            loading: false,
            sendModal: false
        };

        ReactGA.initialize("UA-173154606-1");
        ReactGA.pageview("/Checklist");
    }

    prepareData = moves => {
        let movesQtZero = [];
        let lastMove = '';
        for (let move of moves) {
            movesQtZero.push({
                nrProposta: move.nrProposta,
                nmEquipamento: move.nmEquipamento,
                qtEquipamento: 0,
                received: "0",
                data: move.data,
                tipo: move.tipo,
                numero: move.numero
            });
        if (move.data) lastMove = move.data;
        }
        let movesNoDuplicates = util.removeDuplicates(
            movesQtZero,
            "nmEquipamento"
        );
        for (let move of moves) {
            for (let newMove of movesNoDuplicates) {
                if (move.nmEquipamento == newMove.nmEquipamento)
                    newMove.qtEquipamento += move.qtEquipamento;
            }
        }
        this.setState({ moves: movesNoDuplicates, lastMove });
        // console.log(this.state.moves);
    };

    sendEmail = () => {
        let message = `Lista de produtos: `;
        let productsMessage = ``;
        for (let item of this.state.moves) {
            let comma =
                this.state.moves.indexOf(item) !== this.state.moves.length - 1
                    ? `, `
                    : "";
            if (item.qtEquipamento > 0)
                if (+item.received === 0)
                    productsMessage += `${item.nmEquipamento} - ☓${comma}`;
                else if (item.qtEquipamento > 0) {
                    if (+item.received > item.qtEquipamento)
                        productsMessage += `${
                            item.nmEquipamento
                        } - ${+item.received -
                            item.qtEquipamento} unidades a mais${comma}`;
                    else if (+item.received < item.qtEquipamento)
                        productsMessage += `${
                            item.nmEquipamento
                        } - ${+item.qtEquipamento -
                            item.received} unidades a menos${comma}`;
                    else productsMessage += `${item.nmEquipamento} - ✓${comma}`;
                }
        }
        _api.post(`/newemail`, {
            subject: "SH - Inventário de seu contrato",
            body: `${message}${productsMessage}`,
            to: this.state.email
        })
            .then(response => {
                NotificationManager.success(
                    "O e-mail contendo o inventário foi enviado com sucesso",
                    "Sucesso",
                    5000
                );
                this.setState({
                    email: "",
                    sendModal: false
                });
            })
            .catch(error => {
                NotificationManager.error(
                    "O e-mail contendo o inventário falhou, tente novamente mais tarde",
                    "Erro",
                    5000
                );
            });
    }; /* Mudança de nome, de "Checklist" para "Inventário". */

    componentDidMount = () => {
        api.defaults.headers.common = {
            Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("token")).accessToken
            }`
        };
        _api.defaults.headers.common = {
            Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("_token")).accessToken
            }`
        };
        api.get(
            `/api/contrato/${this.state.contractId}/MovimentacoesEquipamento`
        ).then(response => {
            if (response.status == 200) {
                this.prepareData(response.data);
            }
        });
    };

    render() {
        return (
            <div className="checklist-container">
                <NotificationContainer />
                <Header />
                <Breadcrumbs
                    items={[
                        {
                            title: "Home",
                            link: `/home?contract=${this.state.contractId}`
                        },
                        {
                            title: "Nova Movimentação",
                            link: `/drives?contact=${this.state.contractId}`
                        },
                        { title: "Inventário" } 
                    ]} /* Mudança de nome, de "Checklist" para "Inventário". */
                />
                <ContractsPicker />
                <Row>
                    <Col xs={{ size: 12 }}>
                        {
                            this.state.lastMove
                                ? <p className="date">
                                    { `Última movimentação dia ${moment(this.state.lastMove).format('L')}` }
                                  </p>
                                : null
                        }                        
                        <div className="table-border">
                            {this.state.moves.length > 0 ? (
                                <Table size="sm" borderless>
                                    <thead>
                                        <tr>
                                            <th>Produto</th>
                                            <th>Quantidade</th>
                                            <th>Contagem</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.moves
                                            .filter(r => {
                                                return r.qtEquipamento > 0;
                                            })
                                            .map((value, i) => {
                                                // console.log(value);
                                                return (
                                                    <tr key={i}>
                                                        <td className="nm-equip">
                                                            {
                                                                value.nmEquipamento
                                                            }
                                                        </td>
                                                        <td>
                                                            <span>
                                                                {
                                                                    value.qtEquipamento
                                                                }
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type="number"
                                                                placeholder="0"
                                                                onChange={e => {
                                                                    value.received =
                                                                        e.target.value;
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </Table>
                            ) : (
                                <div className="no-items">
                                    <strong>Itens não disponíveis</strong>
                                </div>
                            )}
                        </div>
                        <br />
                        <Button
                            className="btn btn-red btn-block"
                            style={buttonTheme}
                            disabled={this.state.moves.length === 0}
                            onClick={() => {
                                this.setState({ sendModal: true });
                            }}
                        >
                            Enviar por e-mail
                        </Button>
                        <div class="w-100" style={{ marginTop: 20 }}>                            
                            <p class="disclaimer-font">*Vale a cláusula do contrato.</p>                                                        
                        </div>
                    </Col>
                </Row>
                <Modal centered isOpen={this.state.sendModal}>
                    <ModalHeader>ENVIAR INVENTÁRIO VIA E-MAIL</ModalHeader> {/* Mudança de nome, de "Checklist" para "Inventário". */}
                    <ModalBody>
                        <div
                            className="result-feedback"
                            style={{
                                width: "100%",
                                heigth: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <Input
                                type="email"
                                value={this.state.email}
                                placeholder="E-mail de destino"
                                onChange={e => {
                                    this.setState({ email: e.target.value });
                                }}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            style={buttonTheme}
                            disabled={this.state.email.length < 1}
                            onClick={() => {
                                this.sendEmail();
                            }}
                        >
                            ENVIAR
                        </Button>
                        <Button
                            className="btn-hollow"
                            style={buttonTheme}
                            onClick={() => {
                                this.setState({ sendModal: false });
                            }}
                        >
                            FECHAR
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}
