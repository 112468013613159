import React, { Component } from "react";
import "./style.css";
import {
    Label,
    Input,
    Form,
    FormGroup,
    Row,
    Col,
    Button,
    Table,
    Spinner,
    Container,
    text
} from "reactstrap";
import api from "../../Services/api.js";
import _api from "../../Services/_api.js";
import axios from "axios";
import util from "../../util";
import Header from "../../components/header";
import InputMask from "react-input-mask";
import { Redirect, Link } from "react-router-dom";
import {
    NotificationContainer,
    NotificationManager
} from "react-notifications";
// import ContractsPicker from "../../components/contract_selector";
// import Breadcrumbs from "../../components/breadcrumbs";
import PageTitle from "../../components/pageTitle";
import Sidebar from "../../components/sidebar";
import moment from "moment";
import "moment/locale/pt-br";
import { button as buttonTheme } from "../../themes";
import { history } from "../../helper/history";
import ReactGA from "react-ga";

export default class CommercialVisit extends Component {
    constructor() {
        super();
        this.state = {
            redirect: "",
            // contractId: util.queryParams().contract,
            // contractId: util.queryParams().contract ?
            //     util.queryParams().contract
            //     : "0",
            contractId: util.queryParams().contract ?
                util.queryParams().contract
                : JSON.parse(localStorage.getItem('userContract')).propostaContratoGuid,
            contractDetails: [],
            data: "",
            reason: "",
            loading: false
        };
        ReactGA.initialize("UA-173154606-1");
        ReactGA.pageview("/solicitacaovisitacomercial");
    }

    componentWillMount = () => {
        api.defaults.headers.common = {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("token")).accessToken
                }`
        };

        _api.defaults.headers.common = {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("_token")).accessToken
                }`
        };

        this.getContractDetails(this.state.contractId);

        document.title = `SH - Solicitação de Visita Comercial`;
    };

    getContractDetails = async id => {
        const promises = [api.get(`/api/contrato/${id}/detalhes`)];
        await Promise.all(promises).then(response => {
            for (let x = 0; x < response.length; x++) {
                let resp = response[x];

                console.log(resp)

                switch (x) {
                    case 0:
                        this.setState({ contractDetails: resp.data[0] });
                        break;
                }
            }
        });
    };

    handleSubmit = async e => {
        e.preventDefault()
        this.setState({ loading: true })
        let {
            contractId,
            contractDetails,
            data,
            reason
        } = this.state;

        const date = moment(data).format("LL")

        try {
            const { nomeCliente, emailVendedor, emailSac } = this.state.contractDetails

            const userContract = JSON.parse(localStorage.getItem("userContract"));

            const { numeroProposta, anoProposta, nomeUnidade, nomeObra } = userContract;

            await _api.post('/newEmail', {
                to: [emailVendedor, emailSac],
                subject: "SH - Solicitação de visita comercial",
                template: "commercialVisit",
                body: {
                    contractId: `${numeroProposta ? numeroProposta.toString().padStart(4, "0") : ""}/${anoProposta}-${nomeUnidade} - ${nomeObra}`,
                    userName: nomeCliente,
                    userEmail: localStorage.getItem("email"),
                    userPhone: localStorage.getItem("telephone"),
                    date,
                    reason
                }
            });

            this.setState({ loading: false })

            NotificationManager.success(
                "Sua solicitação de agendamento de visita comercial foi registrada! Entraremos em contato para confirmar o mais breve possível.",
                "Sucesso",
                5000)

            setTimeout(() => history.push("/home"), 5000);

        } catch (err) {
            this.setState({ loading: false })
            NotificationManager.error(
                "Error ao enviar solicitação, tente novamente mais tarde",
                "Erro",
                4000
            )
            throw err
        }
    };

    render() {
        return (

            <>
                <div className="drives-container">
                    <NotificationContainer />
                    {this.state.redirect !== "" ? (
                        <Redirect to={`${this.state.redirect}`} />
                    ) : null}
                    <Header />
                    {/* <Breadcrumbs
                        items={[
                            {
                                title: "Contrato",
                                link: `/home?contract=${this.state.contractId}`
                            },
                            { title: "Solicitação de Visita Comercial" }
                        ]}
                    />
                    <ContractsPicker /> */}
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                        }}
                    >
                        <Sidebar />
                        <div
                            style={{
                                width: "100%"
                            }}
                        >
                            <PageTitle
                                title='Solicitação de Visita Comercial'
                                imgPath='./icons/sidebar/menu_nova_visita.svg'
                            />
                            <div
                                className="page-body"
                            >
                                <form onSubmit={this.handleSubmit}>
                                    <Container>
                                        <Row>
                                            <Col
                                                className="config-step"
                                                xs={12}
                                            >
                                                <Row>
                                                    <Col style={{ border: "1px solid grey", padding: ".75rem 1.25rem", borderRadius: 5 }}>
                                                        <p className="step-title">
                                                            CONTRATO{" "}
                                                            <span className="highlight-text">
                                                                {
                                                                    this.state.contractDetails
                                                                        .numeroContrato
                                                                }
                                                            </span>
                                                        </p>
                                                        <div className="step-container contract-details">
                                                            <p>
                                                                Obra:{" "}
                                                                <span className="highlight-text">
                                                                    {
                                                                        this.state.contractDetails
                                                                            .nmObra
                                                                    }
                                                                </span>
                                                                <br />
                                                                Cliente:{" "}
                                                                <span className="highlight-text">
                                                                    {
                                                                        this.state.contractDetails
                                                                            .nomeCliente
                                                                    }
                                                                </span>
                                                                <br />
                                                                Contratante:{" "}
                                                                <span className="highlight-text">
                                                                    {
                                                                        this.state.contractDetails
                                                                            .nomeContratante
                                                                    }
                                                                </span>
                                                            </p>
                                                            <hr />
                                                            <p>
                                                                Venda:{" "}
                                                                <span className="highlight-text">
                                                                    {
                                                                        this.state.contractDetails
                                                                            .nomeVendedor
                                                                    }
                                                                </span>
                                                                <br />
                                                                Logistica:{" "}
                                                                <span className="highlight-text">
                                                                    {
                                                                        this.state.contractDetails
                                                                            .emailFuncLogistica
                                                                    }
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col
                                                className="config-step"
                                                xs={12}
                                            >
                                                <Row>
                                                    <div class="alert alert-danger w-100">
                                                        <h4 class="alert-heading">Atenção!</h4>
                                                        <p class="font-weight-normal">Esse procedimento é apenas de solicitação de visita comercial e depende da confirmação da equipe comercial da SH.</p>
                                                        <hr />
                                                        <p class="mb-0 font-weight-normal">Aguarde um retorno em breve!</p>
                                                    </div>
                                                    <Col xs={12} md={6} className="pl-0">
                                                        <p className="step-title">
                                                            Data para Visita
                                                        </p>
                                                        <div className="step-container availability">
                                                            <FormGroup>
                                                                <Input
                                                                    required
                                                                    style={{ marginBottom: 10 }}
                                                                    placeholder="Data"
                                                                    value={this.state.data}
                                                                    onChange={e => {
                                                                        this.setState({
                                                                            data: e.target.value
                                                                        });
                                                                    }}
                                                                    type="date"
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} md={12} className="pl-0">
                                                        <p className="step-title">
                                                            Motivo/Assunto
                                                        </p>
                                                        <div className="step-container availability">
                                                            <FormGroup>
                                                                <Input
                                                                    type="textarea"
                                                                    rows="5"
                                                                    required
                                                                    value={this.state.reason}
                                                                    onChange={e => {
                                                                        this.setState({
                                                                            reason: e.target.value
                                                                        });
                                                                    }}
                                                                    placeholder="Motivo"
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Button
                                                        className="btn-hollow d-block"
                                                        style={buttonTheme}
                                                        block={window.innerWidth <= 1000}>
                                                        {
                                                            this.state.loading
                                                                ? <Spinner style={{ height: 25, width: 25 }} />
                                                                : 'SOLICITAR'
                                                        }
                                                    </Button>
                                                </Row>
                                            </Col>

                                        </Row>
                                        <Row>
                                        </Row>
                                    </Container>
                                </form>
                                <Row>
                                    <Col md={{ size: 12 }}>
                                        <a href="/catalog">
                                            <img
                                                style={{
                                                    margin: "auto",
                                                    marginTop: "55px", width: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                                src={"./images/banners/catalogo.png"}
                                            />
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
